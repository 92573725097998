/*
  Project: Cfms Blog
  Author: Rafael
 */
const $ = window.jQuery;

function initMobileMenu() {
  const $menuToggle = $('.navbar-toggle');
  const $menuMobile = $('.c-header__right-list--toggleable');
  const menuOpenClass = 'navbar-drop-open';

  $menuToggle.click(function (e) { // eslint-disable-line
    e.preventDefault();
    $menuMobile.toggleClass(menuOpenClass);
  });
}

function initDropdownMenu() {
  const $menuToggle = $('.menu-item-has-children > a');
  const menuOpenClass = 'dropdown--open';

  $menuToggle.click(function (e) { // eslint-disable-line
    e.preventDefault();
    $(this).next().toggleClass(menuOpenClass);
  });
}

function initPopup() {
  const $trigger = $('.js-help');
  $trigger.magnificPopup({
    type: 'inline',
    midClick: true,
  });
}

$(() => {
  initMobileMenu();
  initDropdownMenu();
  initPopup();
});
